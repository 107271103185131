<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title>Edit Maintenance
				<span class="numFont title">
					{{ form.maintenanceId }}
				</span>
			</b-card-title>
			<b-card-sub-title>Handles the updates of existing maintenances for all companies </b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<b-form class="mt-4" @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<!-- AREA -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion-1 variant="primary"
								class="text-sm-left accordion-title">
								PRIMARY DETAILS
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion-1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Dispatch ID" label-for="Dispatch ID">
											<v-select name="Dispatch ID" class="style-chooser" label="text"
												placeholder=" - Please select - " :options="dispatchOptions"
												:reduce="(dispatch) => dispatch.value" v-model="selDispatch"
												v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Company')" class="help-block">
												{{ errors.first('Company') }}
											</span>
										</b-form-group>
										<b-form-group label="Asset Type" label-for="Asset Type">
											<v-select name="Asset Type" class="style-chooser" label="text"
												placeholder=" - Please select - " :options="assetTypeOptions"
												:reduce="(assetType) => assetType.value" v-model="selAssetType"
												v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Storage Location')" class="help-block">
												{{ errors.first('Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col v-show="isNonNull(classification)" lg="4" md="6" sm="12" class="ml-4">
										<b-row class="my-2">
											<b-col sm="12">
												<b>Classification Details</b>
											</b-col>
										</b-row>
										<b-form-group label="Area" label-for="Area">
											<i class="fa fa-map-marker"></i>
											<i class="area-value">{{ getArea() }}</i>
										</b-form-group>
										<b-row class="my-2">
											<b-col sm="12">
												<b>Total: {{ totalAssetCount }}</b>
											</b-col>
										</b-row>
										<b-row class="my-2">
											<div class="classification-chart"> <!-- Set your desired dimensions here -->
												<canvas id="classification-chart"></canvas>
											</div>
										</b-row>
										<b-row class="my-2">
											<b-col sm="12">
												<b>Supporting Documents</b>
											</b-col>
										</b-row>
										<b-row class="my-2" v-if="isNonNull(classification)">
											<b-col sm="12">
												<div v-for="(document, index) in classification.documents" :key="index"
													class="document-file">
													<span v-if="document !== null">
														<i @click="onShowImage(document.url)">{{ document.name }}</i>
													</span>
												</div>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- REPAIR DOCUMENTS -->
					<b-card v-if="isNonNull(classification)" no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion-2 variant="primary"
								class="text-sm-left accordion-title">
								REPAIR DOCUMENTS
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<b-form-group>
											<template #description>
												<span style="font-style: italic;">Maximum upload of three (3) documents
													only</span>
											</template>
											<b>UPLOAD DOCUMENT(S)</b>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col sm="4">
										<b-form-group label="Document 1"
											description="*32-bit PNG, 2000px by 2000px, up to 1 MB">
											<b-form-file id="document-file-0" placeholder="Choose image"
												ref="document-file-0" accept="image/png, image/jpeg, image/jpg"
												@change="onSelectDocument($event, 0)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group label="Document 2"
											description="*32-bit PNG, 2000px by 2000px, up to 1 MB">
											<b-form-file id="document-file-1" placeholder="Choose image"
												ref="document-file-1" accept="image/png, image/jpeg, image/jpg"
												@change="onSelectDocument($event, 1)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group label="Document 3"
											description="*32-bit PNG, 2000px by 2000px, up to 1 MB">
											<b-form-file id="document-file-2" placeholder="Choose image"
												ref="document-file-2" accept="image/png, image/jpeg, image/jpg"
												@change="onSelectDocument($event, 2)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="isNonNullDocs(selDocuments[0])">
											<b-card overlay :img-src="selDocuments[0].url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDocument(0)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="selDocuments[0].status === 'uploading'"
												:value="selDocuments[0].percentage" :max="selDocuments[0].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>

									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="isNonNullDocs(selDocuments[1])">
											<b-card overlay :img-src="selDocuments[1].url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDocument(1)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="selDocuments[1].status === 'uploading'"
												:value="selDocuments[1].percentage" :max="selDocuments[1].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>

									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="isNonNullDocs(selDocuments[2])">
											<b-card overlay :img-src="selDocuments[2].url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDocument(2)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="selDocuments[2].status === 'uploading'"
												:value="selDocuments[2].percentage" :max="selDocuments[2].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>
								</b-row>

								<b-row class="mt-4">
									<b-col lg="4" md="4" sm="12">
										<b-form-group v-if="isNonNullDocs(selDocuments[0])" label="Remarks"
											label-for="Remarks" description>
											<b-form-textarea name="Document 1 Remarks" type="text"
												v-model="selDocuments[0].remarks" maxlength="200"
												v-validate="getValidationParam(true, remarksRegex)" :rows="3"
												placeholder="Remarks" />
											<span v-show="errors.has('Document 1 Remarks')" class="help-block">
												{{ errors.first('Document 1 Remarks') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="4" sm="12">
										<b-form-group v-if="isNonNullDocs(selDocuments[1])" label="Remarks"
											label-for="Remarks" description>
											<b-form-textarea name="Document 2 Remarks" type="text"
												v-model="selDocuments[1].remarks" maxlength="200"
												v-validate="getValidationParam(true, remarksRegex)" :rows="3"
												placeholder="Remarks" />
											<span v-show="errors.has('Document 2 Remarks')" class="help-block">
												{{ errors.first('Document 2 Remarks') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="4" sm="12">
										<b-form-group v-if="isNonNullDocs(selDocuments[2])" label="Remarks"
											label-for="Remarks" description>
											<b-form-textarea name="Document 3 Remarks" type="text"
												v-model="selDocuments[2].remarks" maxlength="200"
												v-validate="getValidationParam(true, remarksRegex)" :rows="3"
												placeholder="Remarks" />
											<span v-show="errors.has('Document 3 Remarks')" class="help-block">
												{{ errors.first('Document 3 Remarks') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- REPAIR BATCHES -->
					<b-card v-if="isNonNull(classification)" no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion-3 variant="primary"
								class="text-sm-left accordion-title">
								REPAIR BATCHES
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<b>SUMMARY</b>
									</b-col>
								</b-row>
								<b-row class="my-4">
									<b-col v-for="( repairCondition, key ) in selRepairCondition " :key="key" lg="3"
										sm="12">

										<b-row class="mb-3">
											<b-col class="rl-container">
												<b>{{ repairCondition.name }}</b> <br />
												<span> {{ repairCondition.repaired + '/' + repairCondition.quantity }}
												</span> <br />
												<strong>Remaining: {{ getRemaining(repairCondition) }}</strong>
											</b-col>
										</b-row>
										<b-form-group label="Repaired" label-class="font-weight-bold pt-0"
											label-cols-md="4" label-align-sm="left" label-align-md="right">
											<b-progress :max="repairCondition.quantity" height="1rem" variant="danger">
												<b-progress-bar :value="repairCondition.repaired">
													<span>
														<strong>{{ repairCondition.repaired }} /
															{{ getRemaining(repairCondition) }}</strong>
													</span>
												</b-progress-bar>
											</b-progress>
										</b-form-group>
										<b-form-group label="Checked" label-cols-md="4"
											label-class="font-weight-bold pt-0" label-align-sm="left"
											label-align-md="right">
											<b-progress :max="repairCondition.quantity" height="1rem" variant="danger">
												<b-progress-bar :value="repairCondition.checked">
													<span>
														<strong>{{ repairCondition.checked }} /
															{{ getRemaining(repairCondition) }}</strong>
													</span>
												</b-progress-bar>
											</b-progress>
										</b-form-group>
										<b-form-group label="Endorsed" label-cols-md="4"
											label-class="font-weight-bold pt-0" label-align-sm="left"
											label-align-md="right">
											<b-progress :max="repairCondition.quantity" height="1rem" variant="danger">
												<b-progress-bar :value="repairCondition.endorsed">
													<span>
														<strong>{{ repairCondition.endorsed }} /
															{{ getRemaining(repairCondition) }}</strong>
													</span>
												</b-progress-bar>
											</b-progress>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-3 mt-2">
									<b-col class="ml-3" sm="5">
										<b-button v-b-tooltip.hover.top="'Create Batch to repair'" variant="dark"
											@click.stop="onCreateBatch()" class="mr-1 mt-1">
											<em class="fa fa-plus"></em> Create Batch
										</b-button>
									</b-col>
								</b-row>
								<b-row class="my-4">
									<b-col class="mx-3">
										<b-table show-empty striped hover :items="items" :fields="fields"
											:current-page="currentPage" :per-page="perPage" :filter="filter"
											:sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
											:sort-direction="sortDirection" responsive>

											<template v-slot:cell(repaired)="row">
												<div v-if="isNonNullStr(row.item.repaired.repairedBy)">
													<span v-for="( item, index ) in row.item.classifications "
														:key="index">
														{{ `${item.condition} : ${item.quantity} ` }} <br />
													</span>
												</div>
											</template>
											<template v-slot:cell(checked)="row">
												<div v-if="isNonNullStr(row.item.checked.checkedBy)">
													<span v-for="( item, index ) in row.item.classifications "
														:key="index">
														{{ `${item.condition} : ${item.quantity} ` }} <br />
													</span>
												</div>
											</template>
											<template v-slot:cell(endorsed)="row">
												<div v-if="isNonNullStr(row.item.endorsed.endorsedBy)">
													<span v-for="( item, index ) in row.item.classifications "
														:key="index">
														{{ `${item.condition} : ${item.quantity} ` }} <br />
													</span>
												</div>
											</template>
											<template v-slot:cell(status)="row">
												<span>{{ getBatchStatus(row.item) }}</span>
											</template>
											<template v-slot:cell(actions)="row">
												<RepairedBatchRowActions :row="row"
													:selRepairCondition="selRepairCondition"
													:selAssetType="selAssetType" :isSuperAdmin="isSuperAdmin"
													:isViewer="isViewer" />
											</template>
										</b-table>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToMaintenanceMain" class="mr-2">
						Back
					</b-button>
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>

		<!-- Modals here -->
		<CreateRepairedBatch />
		<CheckRepairedBatch />
		<EndorseRepairedBatch />
		<ImageViewDialog />
		<AlertDialog />
	</div>
</template>

<script>
// Components
import RepairedBatchRowActions from './RepairedBatchRowActions';
import CreateRepairedBatch from './CreateRepairedBatch';
import CheckRepairedBatch from './CheckRepairedBatch';
import EndorseRepairedBatch from './EndorseRepairedBatch';
import ImageViewDialog from '../common/ImageViewDialog';
import AlertDialog from '../common/AlertDialog';

// Utils
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ImageUtil } from '@/utils/imageUtil';
import { FileUtil } from '@/utils/fileUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// API & DAO
import maintenanceApi from '@/api/maintenanceApi';
import classificationDAO from '@/database/classifications';
import maintenanceDAO from '@/database/maintenances'

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { storage } from '@/config/firebase';
import Chart from 'chart.js';
import _ from 'lodash';

export default {
	name: 'edit-maintenance',
	components: {
		Loading,
		RepairedBatchRowActions,
		CreateRepairedBatch,
		CheckRepairedBatch,
		EndorseRepairedBatch,
		ImageViewDialog,
		AlertDialog,
	},
	data() {
		return {
			items: [],
			fields: [
				'batchNo',
				{
					key: 'repaired',
					label: 'Repaired',
					sortable: true,
				},
				{
					key: 'checked',
					label: 'Checked',
					sortable: true,
				},
				{
					key: 'endorsed',
					label: 'Endorsed',
					sortable: true,
				},
				'status',
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			totalAssetCount: 0,
			showFirstPane: true,

			geoaddress: null,
			params: null,
			form: { ...MaintenanceUtil.getMaintenanceDefaultObj() },

			dispatchOptions: [],
			assetTypeOptions: [],

			allDispatchesObj: {},
			allAssetTypesObj: {},

			classification: {},
			selRepairCondition: {},
			selDocuments: [{}, {}, {}],
			selBatchDocs: {},
			selDispatch: { ...config.dispatchDefaultValue },
			selAssetType: { ...config.assetTypeDefaultValue },

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			isViewer: this.$store.getters.isViewer,
			chart: null,
			ctxDOM: null,

			isInitDispatch: false,
			isInitBatches: false,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		maintenanceId() {
			return this.form && this.form.maintenanceId ? this.form.maintenanceId : '';
		},
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	watch: {
		selDispatch: function (newVal) {
			if (newVal && newVal.id) {
				this.onChangeDispatch();
			}
		},
		selAssetType: async function (newVal) {
			if (newVal && newVal.id) {
				await this.onChangeAssetType(newVal.id);
			}
		},
	},
	mounted() {
		setTimeout(async () => {
			// Filter Access
			if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}

			await this.getCurrentLocation();

			// show loading indicator
			this.isLoading = true;

			// init parameters
			this.params = this.$store.getters.maintenanceParams;
			this.form = this.$store.getters.currMaintenance;

			// Objects
			this.allDispatchesObj = this.params.allDispatchesObj;
			this.allAssetTypesObj = this.params.allAssetTypesObj;

			this.dispatchOptions = DropDownItemsUtil.retrieveDispatches(this.allDispatchesObj);
			this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

			this.ctxDOM = document.getElementById('classification-chart');

			this.onReset();

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseRepairedBatch', (batchObj) => {
			this.updateTable(batchObj);
		});

		EventBus.$on('onCloseCheckedBatch', (batchObj) => {
			this.updateTable(batchObj);
		});

		EventBus.$on('onCloseEndorsedBatch', (batchObj) => {
			this.updateTable(batchObj);
		});
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		async getCurrentLocation() {
			try {
				this.geoaddress = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}
		},

		onChangeDispatch() {
			// reset storage fields
			const dispatch = this.allDispatchesObj[this.selDispatch.id];
			const assets = dispatch.assets;

			const assetTypesObj = {};
			for (const asset of assets) {
				assetTypesObj[asset.assetTypeId] = this.allAssetTypesObj[asset.assetTypeId];
			}

			this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypes(assetTypesObj, true);

			if (this.isInitDispatch) {
				const assetType = this.assetTypeOptions.find(
					option =>
						option.value.id === this.form.assetTypeId
				);
				if (assetType) {
					this.selAssetType = assetType.value;
				}

				this.isInitDispatch = false;
			} else {
				this.selAssetType = { ...config.assetTypeDefaultValue };
			}
		},
		async onChangeAssetType(assetTypeId) {
			try {
				if (_.isEmpty(this.selDispatch)) return;

				// show loading indicator
				this.isLoading = true;

				this.classification = await classificationDAO.getClassificationByAssetType({
					id: this.selDispatch.id,
					dispatchId: this.selDispatch.dispatchId,
					assetTypeId: assetTypeId
				});

				if (this.isNonNull(this.classification)) {
					const conditions = this.classification.conditions;
					const quantitiesArr = [];
					const labelsArr = [];
					const colorsArr = [
						`rgb(18, 43, 145)`,
						`rgb(228, 143, 1)`,
						`rgb(12, 124, 89)`,
						`rgb(228, 116, 119)`,
						`rgb(166, 61, 64)`,
					];

					const assetType = this.allAssetTypesObj[assetTypeId];
					this.selRepairCondition = conditions
						.filter(condition => {
							const assetCondition = assetType.conditions.find(item => item.condition === condition.condition);
							return assetCondition && assetCondition.isBillable && assetCondition.isActive;
						})
						.reduce((result, condition) => {
							result[condition.condition] = {
								name: condition.condition,
								quantity: condition.quantity,
								repaired: 0,
								checked: 0,
								endorsed: 0,
							};
							quantitiesArr.push(condition.quantity);
							labelsArr.push(`${condition.handling} : ${condition.quantity}`);
							return result;
						}, {});

					if (this.isInitBatches) {
						this.onInitBatches();
						this.isInitBatches = false;
					}

					this.totalAssetCount = quantitiesArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
					this.chart = new Chart(this.ctxDOM, {
						type: 'pie',
						data: {
							labels: labelsArr,
							datasets: [
								{
									data: quantitiesArr,
									borderWidth: 1,
									backgroundColor: colorsArr,
									fontSize: 20,
								},
							],
						},
						options: {
							legend: {
								position: 'right',
								labels: {
									padding: 10,
									fontStyle: 'bold',
								},
							},
							responsive: true, // Enable responsive sizing
							maintainAspectRatio: false, // Disable aspect ratio constraint
						},
					});
				}

			} catch (error) {
				console.error("Error:", error);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		updateTable(batchObj) {

			this.items = this.items.filter(item => item.batchNo !== batchObj.batchNo);
			this.items.push(batchObj);

			const classifications = batchObj.classifications;

			for (const item of classifications) {
				const condition = this.selRepairCondition[item.condition];

				if (batchObj.repaired && this.isNonNullStr(batchObj.repaired.repairedBy))
					condition.repaired = (item.quantity - item.rejected);

				if (batchObj.checked && this.isNonNullStr(batchObj.checked.checkedBy))
					condition.checked = (item.quantity - item.rejected);

				if (batchObj.endorsed && this.isNonNullStr(batchObj.endorsed.endorsedBy))
					condition.endorsed = (item.quantity - item.rejected);

				this.selRepairCondition[item.condition] = condition;
			}
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			this.parseMaintenance();
			this.parseBatches();

			if (!this.isValid()) {
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		parseMaintenance() {
			// Update Maintenance Fields
			this.form.dispatchId = this.selDispatch.dispatchId;
			this.form.assetType = this.selAssetType.name;
			this.form.assetTypeId = this.selAssetType.id;

			// Area
			let area = this.classification.area;
			this.form.company = area.company;
			this.form.companyId = area.companyId;
			this.form.storageLocation = area.storageLocation;
			this.form.storageLocationId = area.storageLocationId;

			// Timestamps
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			// Conditions
			this.form.conditions = [];
			for (const condition of this.classification.conditions) {
				this.form.conditions.push(condition);
			}

			// Documents
			this.form.documents = [];
			for (let i = 0; i < this.selDocuments.length; i++) {
				const document = this.selDocuments[i];
				if (!_.isEmpty(document)) {
					document.name = `DP_${this.form.maintenanceId}_repair_docs_${i}.jpg`;
					document.geoaddress = {
						latitude: this.geoaddress ? this.geoaddress.lat : 0,
						longitude: this.geoaddress ? this.geoaddress.lng : 0
					};
					this.selDocuments[i] = document;
					this.form.documents.push({
						...ImageUtil.createMedia(document),
						remarks: document.remarks
					});
				}
			}

		},
		parseBatches() {
			this.selBatchDocs = {};

			for (const item of this.items) {
				this.selBatchDocs[item.batchNo] = {
					repaired: {},
					checked: {},
					endorsed: {},
				};

				const types = ['repaired', 'checked', 'endorsed'];
				for (const type of types) {
					const doc = item[type].document;
					if (this.isNonNullDocs(doc)) {
						this.selBatchDocs[item.batchNo][type] = {
							url: doc.url,
							file: doc.file,
							fbStoragePath: doc.fbStoragePath,
							name: doc.name,
							isNew: doc.isNew,
						};
						item[type].document = ImageUtil.createMedia(doc);
					}
				}
			}
		},
		isValid() {
			if (!this.form.dispatchId || this.form.dispatchId.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} no Dispatch ID`);
				return false;
			} else if (!this.form.assetTypeId || this.form.assetTypeId.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} no asset type`);
				return false;
			} else if (!this.form.conditions || this.form.conditions.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} no asset classifications`);
				return false;
			} else if (!this.form.documents || this.form.documents.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} has no supporting documents`);
				return false;
			}
			return true;
		},

		async handleSubmit() {
			let maintenanceId = this.form.maintenanceId;
			try {
				// run the edit maintenance api
				let result = await maintenanceApi.updateMaintenance(this.form, this.loggedUser.id);
				let data = result.data;

				if (data.isSuccess) {
					// Upload document forms
					await this.uploadDocuments(data.maintenance);

					// Save batches
					const batchesObj = await maintenanceDAO.saveBatches(this.items, data.maintenance.id);
					const batchesArr = Object.values(batchesObj);

					if (batchesArr.length === this.items.length) {
						this.items = batchesArr;

						// Upload batch docs	
						await this.uploadBatchDocs(batchesObj);
						await maintenanceDAO.saveBatches(this.items, data.maintenance.id);

						this.$toaster.success(`New maintenance "${maintenanceId}" was created successfully.`);
						EventBus.$emit('onCloseSaveMaintenance', data.maintenance);

						// reset the state and revert to maintenance page
						this.returnToMaintenanceMain();
					} else {
						this.$toaster.success(`There was an error when saving the batches of maintenance "${maintenanceId}".`);
					}

				} else {
					this.$toaster.warning(data.message);
				}

			} catch (error) {
				this.$toaster.error(`Error creating maintenance ${maintenanceId}. Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		async uploadDocuments(maintenance) {
			for (let i = 0; i < this.selDocuments.length; i++) {
				const document = this.selDocuments[i];
				if (document && document.isNew) {
					const result = await this.firebaseUploadImage(document);
					if (result.name && result.url) {
						maintenance.documents[i].url = result.url;
						await maintenanceApi.updateMaintenance(
							maintenance,
							this.loggedUser.id
						);
					}
				}
			}
		},
		async uploadBatchDocs(batches) {
			for (const [id, documents] of Object.entries(batches)) {
				const documentTypes = ['repaired', 'checked', 'endorsed'];

				for (const type of documentTypes) {
					const doc = documents[type];
					if (!_.isEmpty(doc) && doc.isNew) {
						const result = await this.firebaseUploadImage(doc);
						if (result.name && result.url) {
							this.selBatchDocs[id][type].url = result.url;
						}
					}
				}
			}

			for (const item of this.items) {
				const documents = this.selBatchDocs[item.batchNo];
				if (this.isNonNull(documents)) {
					const types = ['repaired', 'checked', 'endorsed'];

					for (const type of types) {
						if (this.isNonNull(item[type]) && this.isNonNullDocs(item[type].document)) {
							item[type].document.url = documents[type].url;
						}
					}
				}
			}
		},
		async firebaseUploadImage(document) {
			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${document.fbStoragePath}/${document.name}`);
				let task = storageRef.put(document.file);

				task.on(
					'state_changed',
					() => { },
					(error) => {
						reject(error);
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: document.name,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		returnToMaintenanceMain() {
			this.params.fromAddMaintenance = true;

			this.$store.dispatch('setMaintenanceParams', this.params);
			this.$store.dispatch('setCurrentMaintenance', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/maintenance' });
			} else {
				this.$router.push({ path: '/admin/admin-maintenance' });
			}
		},

		isNonNullDocs(docs) {
			return docs && !_.isEmpty(docs) && docs.url;
		},
		isNonNullStr(str) {
			return str && str.length !== 0;
		},
		isNonNull(json) {
			return json && !_.isEmpty(json);
		},
		getRemaining(condition) {
			return condition.quantity - condition.repaired;
		},
		getTotalRemaining() {
			let totalRemaining = 0;
			_.forEach(this.selRepairCondition, repairCondition => {
				totalRemaining += this.getRemaining(repairCondition);
			});
			return totalRemaining;
		},
		getArea() {
			return this.classification && this.classification.area ?
				`${this.classification.area.company} - ${this.classification.area.storageLocation}` :
				'-';
		},
		getBatchStatus(batch) {
			if (batch.completed) {
				return 'Done';
			} else if (batch.status === config.batchStatus.IN_PRODUCTION.name) {
				return config.batchStatus.IN_PRODUCTION.nextStatus;
			} else if (batch.status === config.batchStatus.IN_QC.name) {
				return config.batchStatus.IN_QC.nextStatus;
			} else if (batch.status === config.batchStatus.IN_WAREHOUSE.name) {
				return config.batchStatus.IN_WAREHOUSE.nextStatus;
			}

			return '-';
		},

		onInitData(maintenance) {
			this.form = maintenance;
		},
		createRepairedBatch() {
			const totalRemaining = this.getTotalRemaining();
			if (totalRemaining > 0) {
				EventBus.$emit('onCreateRepairedBatch', {
					maintenanceId: this.form.maintenanceId,
					companyId: this.classification.area.companyId,
					repairConditions: this.selRepairCondition,
					batchNo: this.items.length + 1,
				});
				this.$bvModal.show('create-repaired-batch');
			} else {
				EventBus.$emit('onShowAlertDialog', {
					message: 'No more remaining assets to repair.',
				});
				this.$bvModal.show('alert-dialog');
			}
		},
		async onInitBatches() {
			const batchesObj = await maintenanceDAO.getBatches(this.form.id);
			this.items = Object.values(batchesObj);

			for (const item of this.items) {
				const classifications = item.classifications;

				for (const classification of classifications) {
					const condition = this.selRepairCondition[classification.condition];

					if (item.repaired && this.isNonNullStr(item.repaired.repairedBy))
						condition.repaired = (classification.quantity - classification.rejected);

					if (item.checked && this.isNonNullStr(item.checked.checkedBy))
						condition.checked = (classification.quantity - classification.rejected);

					if (item.endorsed && this.isNonNullStr(item.endorsed.endorsedBy))
						condition.endorsed = (classification.quantity - classification.rejected);

					this.selRepairCondition[classification.condition] = condition;
				}
			}
		},
		onCreateBatch() {
			const totalRemaining = this.getTotalRemaining();
			if (totalRemaining > 0) {
				EventBus.$emit('onCreateRepairedBatch', {
					maintenanceId: this.form.maintenanceId,
					companyId: this.classification.area.companyId,
					repairConditions: this.selRepairCondition,
					batchNo: this.items.length + 1,
				});
				this.$bvModal.show('create-repaired-batch');
			} else {
				EventBus.$emit('onShowAlertDialog', {
					message: 'No more remaining assets to repair.',
				});
				this.$bvModal.show('alert-dialog');
			}
		},

		onSelectDocument(evt, id) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error('Invalid File Type: Please import a valid document in PNG or JPEG format.');
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 2000 || dimensions.h > 2000) {
					vm.$toaster.warning("Document's width and height shouldn't be greater than 2000 pixels");
				} else {
					vm.$set(vm.selDocuments, id, {
						url: url,
						file: file,
						fbStoragePath: 'images/maintenances',
						isNew: true,
						percentage: 0,
						status: '',
						remarks: '',
					});
				}
			};
			image.src = url;
		},
		onRemoveDocument(id) {
			this.$set(this.selDocuments, id, {});
			this.$refs[`document-file-${id}`].reset();
		},
		onShowImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName
			});
			this.$bvModal.show('image-view-dialog');
		},

		async onReset() {
			this.isInitDispatch = true;
			this.isInitBatches = true;
			const dispatch = this.dispatchOptions.find(
				option =>
					option.value.dispatchId === this.form.dispatchId
			);
			if (dispatch) {
				this.selDispatch = dispatch.value;
			}

			for (let i = 0; i < this.form.documents.length; i++) {
				this.selDocuments[i] = this.form.documents[i];
			}

			// Set default source company
			this.selSourceCompany = this.isSuperAdmin
				? { ...config.companyDefaultValue }
				: DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style scoped>
.accordion-title {
	color: white !important;
}

.area-value {
	margin-left: 0.5em;
}

.classification-chart {
	width: 400px;
	height: 250px;
}

.document-file {
	font-weight: bold;
	cursor: pointer;
	color: #E48F01;
}

.rl-container {
	text-align: center;
}

.rl-container span {
	font-size: 4em;
	font-family: sans-serif;
}

.rl-container strong {
	color: #F86C6B;
}
</style>
